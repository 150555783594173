import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2021-12-09-schoolnik/body-landing"
import SeriousTargets from "./../screens/webinar-replay/serious-targets"

const SchoolnikView2 = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="STI Testing: New Point-of-Care Advances"
				videoDisplayTitle="STI Testing: New Point-of-Care Advances"
				vimeoLink="https://player.vimeo.com/video/656763048?h=d8fe456972"
				placeholderImage="2022-12-09-NACCHO-Visby.jpg"
				localStorageID="2021-12-09-schoolnik-webinar-replay"
				pageSlug="/webinar-replay/2021-12-09-schoolnik/"
			/>
			<BodyLanding pageName="download" localStorageID="2021-12-09-schoolnik-webinar-replay" />
			<SeriousTargets />
		</React.Fragment>
	)
}
export default SchoolnikView2

export const Head = () => (
  <Seo
		title="Webinar Replay: STI Testing: New Point-of-Care Advances"
		description="This webinar covers a new point-of-care technology for testing of sexually transmitted infections (STIs) and the impact of its use for health departments."
		image="/meta/webinar-schoolnik-2021-12-09.jpg"
  />
)
